// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { DateTime } from 'luxon';
import { type IServiceRequestsList } from '@pages/DashboardPage/config/interfaces';
import { NavLink } from 'react-router-dom';
import { PERSON_TITLE } from '@widgets/DetailServiceRequestWidget/config/constants';
import { TYPE_OF_WORK_LIST } from '@pages/DashboardPage/config/constants';
import IconEmergency from '@shared/assets/Icons/icon_emergency.svg';

interface IDashboardGridViewProps {
    serviceRequestList: IServiceRequestsList[] | undefined
}

const DashboardGridViewWidget: React.FC<IDashboardGridViewProps> = ({ serviceRequestList }) => {
  const { t } = useTranslation();
  return (
    <div className="tab-content">
      <div className="dashboard">
        <div className="dashboard__section dashboard__section--requests">
          <div className="dashboard__section__header">
            {t('dashboard.requests')}
            <span className="mark mark--violet">
              {serviceRequestList?.filter(serviceRequest => serviceRequest.status !== 'CANCELLED' && serviceRequest.status === 'NEW').length}
            </span>
          </div>
          {serviceRequestList?.filter(serviceRequest => serviceRequest.status !== 'CANCELLED' && serviceRequest.status === 'NEW')
            .map(serviceRequest => {
              const { id, customer, number, created, category, emergency, distance, description } = serviceRequest;
              const diff = DateTime.fromISO(created).diff(DateTime.now(), ['days']);
              const createdDate = Math.abs(diff.toObject().days as number) > 4
                ? DateTime.fromISO(created).toLocaleString()
                : DateTime.now().setLocale('en').plus(diff).toRelativeCalendar();
              const createdTime = DateTime.fromISO(created).setZone('local').toFormat('HH:mm');
              return (
                <NavLink key={id} to={`/service-request/detail/${id}`}>
                  <div className="dashboard__card" key={id}>
                    <div className="dashboard__card__header">
                      <div className="dashboard__card__date">
                        {createdDate} {createdTime}
                      </div>
                      {emergency && (<><div className="indicator-emergency" style={{ gap: 0 }}>{t('createServiceRequest.emergency')}</div><img src={IconEmergency} width="20" height="20" alt=""/></>)}
                    </div>
                    <div className="dashboard__card__client">
                      <span className="dashboard__card__client--name">
                        {customer?.person_title && (t(PERSON_TITLE[customer?.person_title]) + ' ')}
                        {customer.first_name}&nbsp;{customer.last_name}
                      </span>
                      <span className="status-color">
                        {customer.status}
                      </span>
                    </div>
                    <div className="dashboard__card__type">
                      {category && t(TYPE_OF_WORK_LIST.find(type => type.value === category).label)}
                    </div>
                    <div className="dashboard__card__footer-description">
                      {description}
                    </div>
                    <div className="dashboard__card__footer">
                      <div className="client-id">
                        {'#'}{number}
                      </div>
                      <div className="dashboard__card__location">
                        <span className="text-nowrap">{distance.toFixed(1)}{' km'}</span>
                      </div>
                    </div>
                  </div>
                </NavLink>
              );
            })}
        </div>

        <div className="dashboard__section dashboard__section--in-progress">
          <div className="dashboard__section__header">
            {t('dashboard.in-progress')}
            <span className="mark mark--yellow">
              {serviceRequestList?.filter(serviceRequest => serviceRequest.status !== 'CANCELLED' && serviceRequest.status === 'IN_PROGRESS').length}
            </span>
          </div>
          {serviceRequestList?.filter(serviceRequest => serviceRequest.status !== 'CANCELLED' && serviceRequest.status === 'IN_PROGRESS').sort((a, b) => b.mark - a.mark)
            .map(serviceRequest => {
              const { id, customer, number, created, category, mark, distance, description } = serviceRequest;
              const diff = DateTime.fromISO(created).diff(DateTime.now(), ['days']);
              const createdDate = Math.abs(diff.toObject().days as number) > 4
                ? DateTime.fromISO(created).toLocaleString()
                : DateTime.now().setLocale('en').plus(diff).toRelativeCalendar();
              return (
                <NavLink key={id} to={`/service-request/detail/${id}`}>
                  <div className="dashboard__card" key={id}>
                    <div className="dashboard__card__header">
                      <div className="dashboard__card__date">
                        {createdDate}
                      </div>
                      {mark && (<div className="indicator-need-action">{t('detailServiceRequest.action-required')}</div>)}
                    </div>
                    <div className="dashboard__card__client">
                      <span className="dashboard__card__client--name">
                        {customer?.person_title && (t(PERSON_TITLE[customer?.person_title]) + ' ')}
                        {customer.first_name}&nbsp;{customer.last_name}
                      </span>
                      <span className="status-color">
                        {customer.status}
                      </span>
                    </div>
                    <div className="dashboard__card__type">
                      {category && t(TYPE_OF_WORK_LIST.find(type => type.value === category).label)}
                    </div>
                    <div className="dashboard__card__footer-description">
                      {description}
                    </div>
                    <div className="dashboard__card__footer">
                      <div className="client-id">
                        {'#'}{number}
                      </div>
                      <div className="dashboard__card__location">
                        <span className="text-nowrap">{distance.toFixed(1)}{' km'}</span>
                      </div>
                    </div>
                  </div>
                </NavLink>
              );
            })}
        </div>

        <div className="dashboard__section dashboard__section--completed">
          <div className="dashboard__section__header">
            {t('dashboard.completed')}
            <span className="mark mark--orange">{serviceRequestList?.filter(serviceRequest => serviceRequest.status === 'COMPLETED').length}</span>
          </div>
          {serviceRequestList?.filter(serviceRequest => serviceRequest.status !== 'CANCELLED' && serviceRequest.status === 'COMPLETED')
            .map(serviceRequest => {
              const { id, customer, number, created, category, distance, description } = serviceRequest;
              const diff = DateTime.fromISO(created).diff(DateTime.now(), ['days']);
              const createdDate = Math.abs(diff.toObject().days as number) > 4
                ? DateTime.fromISO(created).toLocaleString()
                : DateTime.now().setLocale('en').plus(diff).toRelativeCalendar();
              return (
                <NavLink key={id} to={`/service-request/detail/${id}`}>
                  <div className="dashboard__card" key={id}>
                    <div className="dashboard__card__header">
                      <div className="dashboard__card__date">
                        {createdDate}
                      </div>
                    </div>
                    <div className="dashboard__card__client">
                      <span className="dashboard__card__client--name">
                        {customer?.person_title && (t(PERSON_TITLE[customer?.person_title]) + ' ')}
                        {customer.first_name}&nbsp;{customer.last_name}
                      </span>
                      <span className="status-color">
                        {customer.status}
                      </span>
                    </div>
                    <div className="dashboard__card__type">
                      {category && t(TYPE_OF_WORK_LIST.find(type => type.value === category).label)}
                    </div>
                    <div className="dashboard__card__footer-description">
                      {description}
                    </div>
                    <div className="dashboard__card__footer">
                      <div className="client-id">
                        {'#'}{number}
                      </div>
                      <div className="dashboard__card__location">
                        <span className="text-nowrap">{distance.toFixed(1)}{' km'}</span>
                      </div>
                    </div>
                  </div>
                </NavLink>
              );
            })}
        </div>

        <div className="dashboard__section dashboard__section--paid">
          <div className="dashboard__section__header">
            {t('dashboard.paid')}
            <span className="mark mark--green">{serviceRequestList?.filter(serviceRequest => serviceRequest.status === 'PAID').length}</span>
          </div>
          {serviceRequestList?.filter(serviceRequest => serviceRequest.status === 'PAID')
            .map(serviceRequest => {
              const { id, customer, number, created, category, distance, description } = serviceRequest;
              const diff = DateTime.fromISO(created).diff(DateTime.now(), ['days']);
              const createdDate = Math.abs(diff.toObject().days as number) > 4
                ? DateTime.fromISO(created).toLocaleString()
                : DateTime.now().setLocale('en').plus(diff).toRelativeCalendar();
              return (
                <NavLink key={id} to={`/service-request/detail/${id}`}>
                  <div className="dashboard__card" key={id}>
                    <div className="dashboard__card__header">
                      <div className="dashboard__card__date">
                        {createdDate}
                      </div>
                    </div>
                    <div className="dashboard__card__client">
                      <span className="dashboard__card__client--name">
                        {customer?.person_title && (t(PERSON_TITLE[customer?.person_title]) + ' ')}
                        {customer.first_name}&nbsp;{customer.last_name}
                      </span>
                      <span className="status-color">
                        {customer.status}
                      </span>
                    </div>
                    <div className="dashboard__card__type">
                      {category && t(TYPE_OF_WORK_LIST.find(type => type.value === category).label)}
                    </div>
                    <div className="dashboard__card__footer-description">
                      {description}
                    </div>
                    <div className="dashboard__card__footer">
                      <div className="client-id">
                        {'#'}{number}
                      </div>
                      <div className="dashboard__card__location">
                        <span className="text-nowrap">{distance.toFixed(1)}{' km'}</span>
                      </div>
                    </div>
                  </div>
                </NavLink>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default DashboardGridViewWidget;
