// @ts-nocheck
import React, { type ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import plusIconViolet from '@shared/assets/Icons/icon_plus_accent.svg';

import { type IVideo } from '@widgets/CreateServiceRequestWidget/config/interfaces';

import VideoPlayer from '@widgets/DetailServiceRequestWidget/ui/VideoPlayer';

import { useUploadMediaStart } from '../../model/useUploadMediaStart';
import { useUploadMediaProcess } from '../../model/useUploadMediaProcess';
import { useUploadVideoFinish } from '../../model/useUploadVideoFinish';
import { useDeleteUploadVideo } from '../../model/useDeleteUploadVideo';
import { useUploadVideoCover } from '../../model/useUploadVideoCover';
import { useUploadVideoCoverProcess } from '../../model/useUploadVideoCoverProcess';

import { MEDIA_TYPES } from '../../config/constants';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface IUploadVideoProps {
  videos: IVideo[]
  setVideos: (value: IVideo[]) => void
  serviceRequestId: string
  selectedUser: string | null
}

export interface IVideoDimensions {
  width: number
  height: number
  duration: number
  codec: string
  cover: {
    width: number
    height: number
    size: number
    src: string
    fileCover: File
  }
}

const UploadVideo: React.FC<IUploadVideoProps> = ({ videos, setVideos, serviceRequestId, selectedUser }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const [imageCover, setImageCover] = useState<string>('');
  const [coverKey, setCoverKey] = useState('');
  const [key, setKey] = useState('');
  const [videoDimensions, setVideoDimensions] = useState<IVideoDimensions | null>(null);
  const [videoId, setVideoId] = useState('');
  const fileNameCover = 'cover.jpg';

  // 3 запрос при отправке видео
  const { mutate: uploadVideoFinish } = useUploadVideoFinish({
    videos,
    setVideos,
    setImageCover,
  });

  // 2 запрос при отправке видео
  const { mutate: uploadMediaProcessVideo } = useUploadMediaProcess({
    type: MEDIA_TYPES.VIDEO,
    serviceRequestId,
    dimensionsObj: videoDimensions,
    file,
    finishFunction: uploadVideoFinish,
    key,
    coverKey,
  });

  // 1 запрос при отправке видео
  const { mutate: uploadVideoStart } = useUploadMediaStart({
    processFunction: uploadMediaProcessVideo,
    file,
    setKeyFunction: setKey,
  });

  // 2 запрос при отправке cover
  const { mutate: uploadVideoCoverProcess } = useUploadVideoCoverProcess();

  // 1 запрос при отправке cover
  const { mutate: uploadVideoCover } = useUploadVideoCover({
    processFunction: uploadVideoCoverProcess,
    file: videoDimensions ? videoDimensions.cover.fileCover : null,
    setKeyFunction: setCoverKey,
  });

  const { mutate: deleteUploadVideo } = useDeleteUploadVideo({ id: videoId, videos, setVideos });

  const handleVideoChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files?.[0];
    if (file) {
      setFile(file);
      setVideoDimensions(await getVideoDimensions(file));

      const newVideoCover = {
        file_name: fileNameCover,
        service_request_id: serviceRequestId,
      };
      uploadVideoCover(newVideoCover);

      const newVideo = {
        file_name: file.name,
        service_request_id: serviceRequestId,
      };
      uploadVideoStart(newVideo);
    }

    e.target.value = ''; // Сброс значения input после выбора файла
  };

  const getVideoDimensions = async (file: File): Promise<IVideoDimensions> => {
    return await new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = async () => {
        URL.revokeObjectURL(video.src);
        const cover = await generateCover(video, fileNameCover);
        resolve({
          width: video.videoWidth,
          height: video.videoHeight,
          duration: video.duration,
          codec: determineCodec(video),
          cover: {
            width: cover.width,
            height: cover.height,
            size: cover.size,
            fileCover: cover.fileCover,
          },
        });
      };
      video.onerror = (error) => {
        reject(error);
      };
      video.src = URL.createObjectURL(file);
    });
  };

  const waitForVideoReady = async (video: HTMLVideoElement): Promise<void> => {
    await new Promise((resolve, reject) => {
      if (video.readyState >= 2) {
        resolve();
      } else {
        const onLoadedData = (): void => {
          resolve();
          video.removeEventListener('loadeddata', onLoadedData);
        };
        const onError = (e: Event): void => {
          reject(new Error(`Video error: ${e.type}`));
          video.removeEventListener('error', onError);
        };
        video.addEventListener('loadeddata', onLoadedData);
        video.addEventListener('error', onError);
      }
    });
  };

  const generateCover = async (video: HTMLVideoElement, fileName: string): Promise<{
    width: number
    height: number
    size: number
    src: string
    fileCover: File
  }> => {
    try {
      await waitForVideoReady(video);

      return await new Promise((resolve, reject) => {
        const captureFrame = (): void => {
          try {
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const ctx = canvas.getContext('2d');
            if (ctx) {
              ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
              const coverSrc = canvas.toDataURL('image/jpeg');
              const byteString = atob(coverSrc.split(',')[1]);
              const mimeString = coverSrc.split(',')[0].split(':')[1].split(';')[0];
              const ab = new ArrayBuffer(byteString.length);
              const ia = new Uint8Array(ab);
              for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
              }
              const blob = new Blob([ab], { type: mimeString });
              const file = new File([blob], fileName, { type: 'image/jpeg' });
              resolve({
                width: canvas.width,
                height: canvas.height,
                size: blob.size,
                src: coverSrc,
                fileCover: file,
              });
            } else {
              reject(new Error('Could not get canvas context'));
            }
          } catch (error) {
            reject(error);
          }
        };

        const handleSeeked = (): void => {
          video.removeEventListener('seeked', handleSeeked);
          captureFrame();
        };

        const handleError = (e: Event): void => {
          reject(new Error(`Video error during seek: ${e.type}`));
        };

        video.addEventListener('seeked', handleSeeked);
        video.addEventListener('error', handleError, { once: true });

        if (video.readyState >= 2) {
          setTimeout(() => {
            video.currentTime = 1;
          }, 200);
        } else {
          video.addEventListener('loadeddata', () => {
            setTimeout(() => {
              video.currentTime = 1;
            }, 200);
          }, { once: true });
        }
      });
    } catch (error) {
      throw new Error(`Failed to generate cover: ${error.message}`);
    }
  };

  const determineCodec = (video: HTMLVideoElement): string => {
    const codec = video.canPlayType('video/mp4');
    if (codec === 'probably' || codec === 'maybe') {
      return 'H.264';
    } else {
      return 'Unknown';
    }
  };

  const handleRemoveVideo = (index: string): void => {
    deleteUploadVideo(index);
  };

  return (
    <div className={cx('form__row')}>
      <div className={cx('form__label')}>{t('createServiceRequest.video')}</div>
      <div className={cx('form__text')}>{t('createServiceRequest.video-info')}</div>
      <div className={cx('files-set')}>
        {videos.length > 0 &&
            <VideoPlayer
              createServiceRequest
              videos={videos}
              coverSrc={imageCover}
              setVideoId={setVideoId}
              handleRemoveVideo={handleRemoveVideo}
            />}
        <div className={cx('input--file', (videos.length > 0 || !selectedUser) ? 'is-disabled' : '')}>
          <label
            htmlFor="uploadVideo"
            className={cx('button')}
          >
            <img src={plusIconViolet} width="24" height="24" alt="Add media"/>
            <div style={{ whiteSpace: 'pre-wrap' }}>{t('createServiceRequest.add-media')}</div>
          </label>
          <input
            type="file"
            accept="video/*"
            id="uploadVideo"
            disabled={videos.length > 0}
            style={{ display: 'none' }}
            onChange={handleVideoChange}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadVideo;
