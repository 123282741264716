// @ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '@shared/config/routes/constants';
import { DateTime } from 'luxon';
import IconBack from '@shared/assets/Icons/icon_back.svg';
import IconEdit from '@shared/assets/Icons/icon_edit_violet.svg';
import IconPhone from '@shared/assets/Icons/icon_phone_small.svg';
import IconEmail from '@shared/assets/Icons/icon_letter_small.svg';
import IconFlagEn from '@shared/assets/Icons/flag_en.svg';
import IconFlagDe from '@shared/assets/Icons/flag_de.svg';
import IconFlagFr from '@shared/assets/Icons/flag_fr.svg';
import IconFlagPt from '@shared/assets/Icons/flag_pt.svg';
import IconFlagLb from '@shared/assets/Icons/flag_lb.svg';
import IconPDF from '@shared/assets/Icons/icon_pdf.svg';
import IconCalendar from '@shared/assets/Icons/icon_calendar.svg';
import IconClock from '@shared/assets/Icons/icon_clock.svg';
import IconWarning from '@shared/assets/Icons/icon_warning.svg';
import IconConfirm from '@shared/assets/Icons/icon_done.svg';
import IconScheduled from '@shared/assets/Icons/icon_status-scheduled.svg';
import IconInProgress from '@shared/assets/Icons/icon_status-inprogress.svg';
import IconDone from '@shared/assets/Icons/icon_status-done.svg';
import { Box, Button, Collapse, FormControl, IconButton, MenuItem, Select, type SxProps } from '@mui/material';
import ClientsMap from '@widgets/DetailServiceRequestWidget/ui/ClientsMap';
import { useServiceRequest } from '@widgets/DetailServiceRequestWidget/model/useServiceRequest';
import { useServiceRequestUpdate } from '@widgets/DetailServiceRequestWidget/model/useServiceRequestUpdate';
import SliderPhoto from '@widgets/DetailServiceRequestWidget/ui/SliderPhoto';
import VideoPlayer from '@widgets/DetailServiceRequestWidget/ui/VideoPlayer';
import {
  PERSON_TITLE,
  TYPE_OF_WORK_LIST,
} from '@widgets/DetailServiceRequestWidget/config/constants';
import { ScrollToTopOnMount } from '@shared/ui/components/ScrollToTopOnMount';
import { APIProvider } from '@vis.gl/react-google-maps';
import MakeAppointmentWidget from '@widgets/MakeAppointmentWidget';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import IconArrowDown from '@shared/assets/Icons/icon_arrow_down.svg?react';
import { useReport } from '@widgets/DetailServiceRequestWidget/model/useReport';
import PreliminaryInvoiceWidget from '@widgets/PreliminaryInvoiceWidget';
import { useFetchProducts } from '@widgets/MakeAppointmentWidget/model/useFetchProducts';
import CreateInvoiceWidget from '@widgets/CreateInvoiceWidget';
import { useCreateInvoice } from '@widgets/CreateInvoiceWidget/model/useCreateInvoice';
import { useGetInvoice } from '@widgets/CreateInvoiceWidget/model/useGetInvoice';
import { useGenerateReport } from '@widgets/DetailServiceRequestWidget/model/useGenerateReport';
import { ModalWindow } from '@widgets/CreateServiceRequestWidget/ui';
import { useServiceProviderProfile } from '@widgets/HeaderWidget/model/useServiceProviderProfile';
import InvoiceWidget from '@widgets/InvoiceWidget';
import { useCancelServiceRequest } from '@widgets/DetailServiceRequestWidget/model/useCancelServiceRequest';
import { TIME_TYPES, TODO_TYPES } from '@shared/config/common/constants';
import IconEmergency from '@shared/assets/Icons/icon_emergency.svg';
import PDFuploader from '@widgets/DetailServiceRequestWidget/ui/PDFuploader';
import { useConfirmPayment } from '@widgets/DetailServiceRequestWidget/model/useConfirmPayment';

const languages = {
  en: IconFlagEn,
  de: IconFlagDe,
  fr: IconFlagFr,
  lb: IconFlagLb,
  pt: IconFlagPt,
} as Record<string, string>;

const statusIcons = {
  SCHEDULED: IconScheduled,
  FOR_EXECUTION: IconScheduled,
  IN_PROGRESS: IconInProgress,
  COMPLETED: IconDone,
} as Record<string, string>;

interface ITableCellProps {
  text: string
  align?: 'left' | 'right' | 'center' | 'inherit' | 'justify' | undefined
  sx?: SxProps | undefined
}

const DetailServiceRequestWidget: React.FC = () => {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState('empty');
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const onFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const navigate = useNavigate();
  const { id } = useParams();
  const { data, refetch: refetchServiceRequest } = useServiceRequest(id as string);
  const { data: products } = useFetchProducts(id as string);
  const { mutate: updateServiceRequest } = useServiceRequestUpdate(id, setSelectedType);
  const { mutateAsync: createInvoice, data: invoiceData } = useCreateInvoice(id);
  const { data: invoice } = useGetInvoice(data?.invoice_id as string);
  const { data: generatedReportURL, refetch } = useGenerateReport(id as string);
  const { data: profile } = useServiceProviderProfile();

  useEffect(() => {
    refetchServiceRequest();
  }, [refetchServiceRequest]);

  const hasAppointment = !!data?.appointment;
  const allTodosCompleted = data?.todos?.every(todo => todo.status === 'COMPLETED');
  const completedTodo = data?.todos?.findIndex(todo => todo.status === 'COMPLETED') !== -1;
  const nextScheduledTodo = !allTodosCompleted &&
    completedTodo &&
    data?.todos[data?.todos?.findLastIndex(todo => todo.status === 'COMPLETED') + 1].status === 'SCHEDULED';
  const appointmentReschedule = !hasAppointment && completedTodo && nextScheduledTodo;
  const isDeclined = data?.appointment?.status === 'DECLINED';

  const isMakeAppointmentActive = !hasAppointment || appointmentReschedule || isDeclined;

  const [isVisibleAppointment, setVisibleAppointment] = useState<boolean>(false);
  const [isVisiblePreliminaryInvoice, setVisiblePreliminaryInvoice] = useState<boolean>(false);
  const [isVisibleInvoice, setVisibleInvoice] = useState<boolean>(false);
  const [isVisibleResultInvoice, setVisibleResultInvoice] = useState<boolean>(false);
  const [isVisibleErrorModal, setVisibleErrorModal] = useState<boolean>(false);
  const [isVisibleCancelModal, setVisibleCancelModal] = useState<boolean>(false);
  const [isVisibleInvoiceChooseModal, setVisibleInvoiceChooseModal] = useState<boolean>(false);
  const [isVisibleConfirmPayment, setVisibleConfirmPayment] = useState<boolean>(false);
  const [isSuccessSendVisible, setSuccessSendVisible] = useState<boolean>(false);

  const onCancelSuccess = () => {
    setVisibleCancelModal(false);
  };

  const onConfirmPaymentSuccess = () => {
    setVisibleConfirmPayment(false);
  };

  const { mutate: cancelServiceRequest } = useCancelServiceRequest(id as string, onCancelSuccess);
  const { mutate: confirmPayment } = useConfirmPayment(invoice?.id as string, id as string, onConfirmPaymentSuccess);

  const subTotal = products?.length
    ? products?.reduce((accumulator, item) => {
      const quantity = item.quantity;
      const unitPrice = parseFloat(item.unit_price);
      return accumulator + (quantity * unitPrice);
    }, 0)
    : 0;
  const vatPercentage = profile?.vat_rate ? profile?.vat_rate : 0;
  const vatSum = vatPercentage * subTotal / 100;

  const TableTitleItem: React.FC<ITableCellProps> = ({ text, align = 'center' }) => {
    return (
      <TableCell
        align={align}
      >
        {text}
      </TableCell>
    );
  };

  const TodoRow = (props: { row, index }) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [reportId, setReportId] = useState(row.report_id);
    const { data: report } = useReport(reportId as string);
    const handleOpenReport = useCallback(() => {
      setReportId(row.report_id);
      setOpen(prevState => !prevState);
    }, [row.report_id]);
    const cellStyle = {
      borderBottom: `${open ? 'none' : ''}`,
    };
    const isActualStartTime = !!row?.actual_start_time;
    const isActualEndTime = !!row?.actual_end_time;

    const hours = Math.floor(row?.todo_duration / 60);
    const minutes = row?.todo_duration % 60;
    const todoDuration = `${hours ? hours + 'h' : ''} ${minutes ? minutes + 'm' : '0'}`;
    return (
      <React.Fragment>
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '80px' }}>
          <TableCell align="left" className="status-color" style={cellStyle}>
            <img src={statusIcons[row.status]} width="24" height="24" alt=""/>
          </TableCell>
          <TableCell align="left" className="text-gray" style={cellStyle}>
            {t(TODO_TYPES[row?.todo_type])}
          </TableCell>
          <TableCell component="th" scope="row" sx={{ backgroundColor: '#fff' }} style={cellStyle}>
            {(row?.status === 'IN_PROGRESS' || row?.status === 'COMPLETED') && (<Box className="person">
              <Box className="person-avatar">
                { row?.technicians[0]?.first_name[0] }
                { row?.technicians[0]?.last_name[0] }
              </Box>
              { row?.technicians[0]?.first_name }
              {' '}
              { row?.technicians[0]?.last_name }
            </Box>)}
          </TableCell>
          <TableCell align="center" className="text-gray" style={cellStyle}>
            {(row?.status === 'IN_PROGRESS' || row?.status === 'COMPLETED') &&
              DateTime.fromISO(row?.actual_start_time).toFormat('dd.LL.yyyy')}
          </TableCell>
          <TableCell align="center" className="text-gray" style={cellStyle}>
            {(row?.status === 'IN_PROGRESS' || row?.status === 'COMPLETED') &&
              (<>
                {isActualStartTime ? DateTime.fromISO(row?.actual_start_time, { setZone: true }).toFormat('HH') : '-'}:
                {isActualStartTime ? DateTime.fromISO(row?.actual_start_time, { setZone: true }).toFormat('mm') : '-'}
                {isActualEndTime ? '-' : ''}
                {isActualEndTime ? DateTime.fromISO(row?.actual_end_time, { setZone: true }).toFormat('HH') : ''}
                {isActualEndTime ? ':' : ''}
                {isActualEndTime ? DateTime.fromISO(row?.actual_end_time, { setZone: true }).toFormat('mm') : ''}
              </>
              )
            }
          </TableCell>
          <TableCell align="center" className="text-gray" style={cellStyle}>
            {isActualStartTime && !isActualEndTime ? '-' : todoDuration}
          </TableCell>
          <TableCell align="center" className="text-gray" style={{ ...cellStyle, cursor: 'pointer' }} onClick={handleOpenReport}>
            {(row.report_id || row.status === 'IN_PROGRESS') && (<IconButton
              aria-label="expand row"
              size="small"
              disableRipple
            >
              <IconArrowDown className={`${open ? 'icon-arrow-expand' : ''}`} />
            </IconButton>)
            }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0, border: `${!open ? 'none' : ''}` }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box className="technicians-report">
                <>
                  <h4>{t('detailServiceRequest.technicians-list')}</h4>
                  <div className="technicians-list">
                    <div className="person">
                      {row?.technicians.map((technician) => (<Box className="person" key={technician.id}>
                        <Box className="person-avatar">
                          { technician.first_name[0] }
                          { technician.last_name[0] }
                        </Box>
                        { technician.first_name }
                        {' '}
                        { technician.last_name }
                      </Box>))}
                    </div>
                  </div>
                </>
                {!!report?.reschedule_reason && (
                  <>
                    <h4>{t('detailServiceRequest.reason-reschedule')}</h4>
                    <p>{report?.reschedule_reason}</p>
                  </>
                )}
                {row?.todo_type === 'POST_SERVICE_REPORT' && (
                  <>
                    <h4>{t('detailServiceRequest.time')}</h4>
                    <Box className="simple-table-container">
                      <TableContainer
                        component={Paper}
                        className="simple-table-wrapper"
                        sx={{
                          margin: '16px 0 40px',
                        }}
                      >
                        <Table
                          className="simple-table"
                          aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableTitleItem align="left" text="Service/Item" />
                              <TableTitleItem align="left" text="Time"/>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {report?.times?.map((time) => (
                              <TableRow key={time.id}>
                                <TableCell>{t(TIME_TYPES[time.time_type])}</TableCell>
                                <TableCell>{time.duration.split(':')[0]}:{time.duration.split(':')[1]}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </>
                )}
                {row.report_id && (<h4>{t(TODO_TYPES[row.todo_type])}</h4>)}
                <p>{report?.description}</p>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                  {report?.videos?.length > 0 &&
                      <div className="request__videos" style={{ padding: 0 }}>
                        <div className="request__videos__list">
                          <VideoPlayer videos={report.videos}/>
                        </div>
                      </div>}
                  {report?.photos?.length > 0 &&
                      <div className="request__photos" style={{ padding: 0 }}>
                        <div className="request__photos__list">
                          <SliderPhoto images={report?.photos} />
                        </div>
                      </div>}
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  const isAppointmentSelected =
    !allTodosCompleted && data?.status !== 'CANCELLED' && hasAppointment && data?.appointment?.status === 'SELECTED';
  const isAppointmentInProgressAndEditable = data?.status === 'IN_PROGRESS' && data?.appointment?.is_editable;
  const isAppointmentIsNotSelected = hasAppointment && data?.appointment?.status === 'NOT_SELECTED';

  if (file) {
    return <PDFuploader document={file} setDocument={setFile} setVisibleSuccess={setSuccessSendVisible} />;
  } else {
    return (<main>
      <ScrollToTopOnMount/>
      <Button
        variant="text"
        disableRipple
        sx={{
          font: '600 1.6rem/1.6 var(--font-main)',
          padding: '0 8px',
          margin: '0 0 24px',
          gap: '4px',
          boxShadow: 'none !important',
          backgroundColor: 'transparent',
          height: '32px',
          color: 'var(--accent)',
          display: 'flex',
          alignItems: 'center',
          transition: 'all 0.25s linear',
          '&:hover': {
            color: 'var(--accent-hover)',
            backgroundColor: 'transparent',
          },
          '&:active': {
            color: 'var(--accent-active)',
            backgroundColor: 'transparent',
          },
        }}
        onClick={() => {
          navigate(ROUTES.MAIN);
        }}
      >
        <img src={IconBack} width="24" height="24" alt="Back"/>
        {t('detailServiceRequest.to-dashboard')}
      </Button>

      <div className="request">
        <article className="request-article">
          <section className="request__info">
            <div className="request__info__heading">
              <h4>{t('detailServiceRequest.client-request-info')}</h4>
              <IconButton className="button--small" disableRipple>
                <img src={IconEdit} width="32" height="32" alt="edit"/>
              </IconButton>
            </div>
            <div className="request__info__inner">
              <div className="request__info__client">
                <h3>
                  {data?.customer?.person_title && (t(PERSON_TITLE[data?.customer?.person_title]) + ' ')}
                  {data?.customer?.first_name}{' '}
                  {data?.customer?.last_name}
                </h3>
                <ul className="request__info__communication" style={{ justifyContent: 'start' }}>
                  <li style={{ marginRight: '30px' }}>
                    <a href={`mailto:${data?.customer?.email}`}>
                      <img src={IconEmail} width="20" height="20"
                        alt="email"/>
                      {data?.customer?.email}
                    </a>
                  </li>
                  <li>
                    <a href={`tel:${data?.contact_phone}`} className="tel">
                      <img src={IconPhone} width="20" height="20"
                        alt="phone"/>
                      {data?.contact_phone}
                    </a>
                  </li>
                  {/* <li> */}
                  {/*  <a href=""> */}
                  {/*    <img src={IconChat} width="20" height="20" */}
                  {/*      alt="chat"/> */}
                  {/*      Go to chat */}
                  {/*  </a> */}
                  {/* </li> */}
                </ul>

                {/* <dl> */}
                {/*  <dt>Client Status</dt> */}
                {/*  <dd> */}
                {/*    <div className="status-color">{data?.customer?.status}</div> */}
                {/*  </dd> */}
                {/* </dl> */}
                <dl>
                  <dt>{t('detailServiceRequest.address')}</dt>
                  <dd>
                    {data?.address}{', '}
                    {data?.postcode}{' '}
                    {data?.city}
                  </dd>
                </dl>
                <dl>
                  <dt>{t('detailServiceRequest.languages')}</dt>
                  <dd>
                    {data?.customer?.preferred_languages.map(lang =>
                      <img key={lang} src={languages[lang]}
                        width="20" height="15" alt=""/>,
                    )}
                  </dd>
                </dl>
              </div>
              <div className="request__map">
                <div className="request__map__inner">
                  <APIProvider apiKey={import.meta.env.APP_GOOGLE_MAPS_KEY} libraries={['marker']}>
                    <ClientsMap serviceRequestData={data}/>
                  </APIProvider>
                </div>
              </div>
            </div>

            <div className="request__description">
              <h4>{t('detailServiceRequest.problem-description')}</h4>
              <p>{data?.description}</p>
            </div>
            {data?.notes && (<div className="request__description">
              <h4>{t('detailServiceRequest.additional-info')}</h4>
              <p>{data?.notes}</p>
            </div>)}
            {data?.videos?.length > 0 &&
                <div className="request__videos">
                  <h4>{t('detailServiceRequest.videos')}</h4>
                  <div className="request__videos__list">
                    <VideoPlayer videos={data?.videos}/>
                  </div>
                </div>}
            {data?.photos?.length > 0 &&
                <div className="request__photos">
                  <h4>{t('detailServiceRequest.photos')}</h4>
                  <div className="request__photos__list">
                    <SliderPhoto images={data?.photos}/>
                  </div>
                </div>}
          </section>

          <h4>{t('detailServiceRequest.todo')}</h4>
          <section>
            <Box>
              <Box className="simple-table-container">
                <TableContainer
                  component={Paper}
                  className="simple-table-wrapper"
                  sx={{
                    margin: '16px 0 40px',
                  }}
                >
                  <Table
                    className="simple-table"
                    aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableTitleItem text={t('detailServiceRequest.status')}/>
                        <TableTitleItem text={t('detailServiceRequest.task')}/>
                        <TableTitleItem align="left" text={t('detailServiceRequest.executor')}/>
                        <TableTitleItem text={t('detailServiceRequest.date')}/>
                        <TableTitleItem text={t('detailServiceRequest.period')}/>
                        <TableTitleItem text={t('detailServiceRequest.time')}/>
                        <TableTitleItem text=""/>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.todos?.map((todo) => (
                        <TodoRow key={todo.id} row={todo}/>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </section>
        </article>

        <aside className="request-aside">
          <div className="request-aside__details">
            <dl>
              {
                data?.status === 'NEW' && (<dt>
                  <div className="mark-status mark-status--violet">{t('requestStatus.new')}</div>
                </dt>)
              }
              {
                data?.status === 'IN_PROGRESS' && (<dt style={{ width: '60%' }}>
                  <div className="mark-status mark-status--yellow">{t('requestStatus.progress')}</div>
                </dt>)
              }
              {
                data?.status === 'COMPLETED' && (<dt style={{ width: '60%' }}>
                  <div className="mark-status mark-status--orange">{t('requestStatus.completed')}</div>
                </dt>)
              }
              {
                data?.status === 'PAID' && (<dt style={{ width: '60%' }}>
                  <div className="mark-status mark-status--green">{t('requestStatus.paid')}</div>
                </dt>)
              }
              {
                data?.status === 'CANCELLED' && (<dt style={{ width: '60%' }}>
                  <div className="mark-status mark-status--red">{t('requestStatus.canceled')}</div>
                </dt>)
              }
              {data?.emergency && data?.status === 'NEW' && (
                <dd>
                  <>
                    <div className="indicator-emergency">{t('createServiceRequest.emergency')}</div>
                    <img src={IconEmergency} width="20" height="20" alt=""/></>
                </dd>
              )}
            </dl>
            <dl>
              <dt>{t('detailServiceRequest.request-number')}</dt>
              <dd>{'#'}{data?.number}</dd>
            </dl>
            <dl>
              <dt>{t('detailServiceRequest.date-created')}</dt>
              <dd>{!!data && DateTime.fromISO(data.created).toLocaleString()}</dd>
            </dl>
            {products?.length > 0 && data?.status !== 'CANCELLED' &&
              (<>
                <dl>
                  <dt>{data?.invoice_id ? <>{t('detailServiceRequest.price')}</> : <>{t('detailServiceRequest.preliminary-price')}</>}</dt>
                  <dd>{data?.invoice_id && data?.status === 'COMPLETED' ? invoice?.total : (vatSum + subTotal).toFixed(2)}€</dd>
                </dl>
                {data?.invoice_id && (data?.status === 'COMPLETED' || data?.status === 'PAID')
                  ? (<button
                    className="button button--text-small edit-appointments-btn"
                    onClick={() => {
                      setVisibleResultInvoice(true);
                    }}
                  >
                    {t('detailServiceRequest.show-invoice')}
                    <img className="edit-icon" src={IconBack} width="20" height="20" alt="Show invoice"/>
                  </button>)
                  : (<button
                    className="button button--text-small edit-appointments-btn"
                    onClick={() => {
                      setVisiblePreliminaryInvoice(true);
                    }}
                  >
                    {t('detailServiceRequest.show-preliminary-invoice')}
                    <img className="edit-icon" src={IconBack} width="20" height="20" alt="Show Preliminary invoice"/>
                  </button>)}
              </>
              )
            }
          </div>
          {data?.status !== 'CANCELLED' && (<FormControl
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'var(--gray-middle)',
                  borderRadius: '8px',
                  borderWidth: '1px !important',
                  transition: '0.3s',
                },
                '&:hover': {
                  '& fieldset': {
                    borderColor: 'var(--gray-middle)',
                  },
                },
              },
              paddingBottom: '24px',
              borderBottom: '1px solid var(--gray-middle)',
            }}
          >
            <Select
              className="select-wrapper"
              IconComponent={() => null}
              labelId="client-status-label"
              id="client-status-label-select"
              label=""
              value={data?.category ?? selectedType}
              onChange={(e) => {
                const selectedValue = e.target.value;
                updateServiceRequest({ id, selectedValue });
              }}
            >
              {TYPE_OF_WORK_LIST.map((user) => (
                <MenuItem key={user.id} value={user.value} className="select-item">{t(user.label)}</MenuItem>
              ))}
            </Select>
          </FormControl>)}
          {data?.appointment?.status === 'DECLINED' && data?.status !== 'CANCELLED' && (
            <div className="request-aside__details request-aside__details_appointment-details">
              <h4>{t('detailServiceRequest.appointment')}
                <div className="indicator-need-action">{t('detailServiceRequest.action-required')}</div>
              </h4>
              <div className="request-aside__text">{t('detailServiceRequest.client-declined')}</div>
            </div>)}
          {appointmentReschedule && data?.status !== 'CANCELLED' && (
            <div className="request-aside__details request-aside__details_appointment-details">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h4>
                  {t('detailServiceRequest.appointment')}
                </h4>
                <div className="indicator-need-action">{t('detailServiceRequest.action-required')}</div>
              </div>
              <div className="request-aside__text">{t('detailServiceRequest.technician-reschedule')}
              </div>
            </div>)}
          {(isAppointmentSelected || isAppointmentIsNotSelected || isAppointmentInProgressAndEditable) &&
            (<div className="request-aside__details appointment-details">
              <h4>{t('detailServiceRequest.appointment')}</h4>
              {isAppointmentSelected && (
                <div className="request-aside__selected">
                  <div className="selected-options">
                    <div className="selected-date">
                      <img src={IconCalendar} width="20" height="20" alt=""/>
                      {DateTime.fromISO(data?.appointment?.option?.date).toFormat('dd.MM.yyyy')}
                    </div>
                    <div className="selected-time">
                      <img src={IconClock} width="20" height="20" alt=""/>
                      {DateTime.fromFormat(data?.appointment?.option?.start_time, 'HH:mm:ss').toFormat('HH:mm')}-
                      {DateTime.fromFormat(data?.appointment?.option?.end_time, 'HH:mm:ss').toFormat('HH:mm')}
                    </div>
                  </div>
                  {data?.appointment?.option?.technicians.map(technician => {
                    const { id, first_name, last_name } = technician;
                    return (
                      <div key={id} className="details_person">
                        <div className="details_technic-avatar">
                          {first_name[0]}{last_name[0]}
                        </div>
                        <div className="details_option">
                          {first_name} {last_name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {isAppointmentIsNotSelected &&
                (<div className="request-aside__text">{t('detailServiceRequest.waiting-client')}</div>)
              }
              {isAppointmentInProgressAndEditable && (
                <div className="request-aside__change-time">
                  <button className="button button--text-small edit-appointments-btn" onClick={() => {
                    setVisibleAppointment(true);
                  }}>
                    {t('detailServiceRequest.edit-appointment')}
                    <img className="edit-icon" src={IconBack} width="20" height="20" alt="Edit date and time"/>
                  </button>
                </div>)}
            </div>)
          }
          {data?.customer_confirmation !== 'NOT_SELECTED' && data?.status !== 'CANCELLED' &&
            (<div className="request-aside__details request-aside__details_appointment-details">
              <div className="client-confirmation">
                {data?.customer_confirmation === 'CONFIRMED'
                  ? (<><img src={IconConfirm} alt="confirm"/>
                    <div>{t('detailServiceRequest.client-confirm')}</div>
                  </>)
                  : (<><img src={IconWarning} alt="confirm"/>
                    <div>{t('detailServiceRequest.client-not-confirm')}</div>
                  </>)}
              </div>
            </div>)}
          {allTodosCompleted && data?.status === 'IN_PROGRESS' && data?.status !== 'CANCELLED' && !data?.invoice_id &&
            (<div className="request-aside__details request-aside__details_appointment-details">
              <div className="service-delivered-details">
                <h4 style={{ fontWeight: '600' }}>
                  {t('detailServiceRequest.service-delivered-title')}
                </h4>
                <div className="indicator-need-action">{t('detailServiceRequest.action-required')}</div>
              </div>
              <div>{t('detailServiceRequest.confirm-completion')}</div>
            </div>)}
          {allTodosCompleted && data?.status === 'IN_PROGRESS' && data?.status !== 'CANCELLED' &&
            (<button
              className="button button--default"
              onClick={() => {
                setVisibleInvoiceChooseModal(true);
              }}
            >
              {t('detailServiceRequest.confirm')}
            </button>)}
          {allTodosCompleted && data?.status === 'COMPLETED' && data?.status !== 'CANCELLED' && invoice?.status === 'WAITING_FOR_PAYMENT' && invoice?.invoice_type === 'UPLOADED' &&
            (<button
              className="button button--default"
              onClick={() => {
                setVisibleConfirmPayment(true);
              }}
            >
              {t('detailServiceRequest.confirm-payment')}
            </button>)}
          {!allTodosCompleted && data?.status !== 'CANCELLED' && (<button
            className="button button--default"
            disabled={!isMakeAppointmentActive}
            onClick={() => {
              setVisibleAppointment(true);
            }}
          >
            {t('detailServiceRequest.make-appointment')}
          </button>)}
          <MakeAppointmentWidget
            isVisible={isVisibleAppointment}
            setVisible={setVisibleAppointment}
            appointment={data?.appointment}
            isRescheduling={appointmentReschedule}
            isDeclined={isDeclined}
          />
          <PreliminaryInvoiceWidget
            isVisible={isVisiblePreliminaryInvoice}
            setVisible={setVisiblePreliminaryInvoice}
            products={products}
          />
          <CreateInvoiceWidget
            isVisible={isVisibleInvoice}
            setVisible={setVisibleInvoice}
            data={invoiceData?.data || invoice}
          />
          <InvoiceWidget
            isVisible={isVisibleResultInvoice}
            setVisible={setVisibleResultInvoice}
            invoiceId={data?.invoice_id as string}
          />
          <ModalWindow
            isVisible={isVisibleErrorModal}
            setIsVisible={setVisibleErrorModal}
            title={t('modals.report-title')}
            subtitle={t('modals.report-subtitle')}
            description={t('modals.report-description')}
            secondBtnText={t('modals.report-yes')}
            secondBtnAction={() => {
              setVisibleErrorModal(false);
            }}
          />
          <ModalWindow
            isVisible={isVisibleCancelModal}
            setIsVisible={setVisibleCancelModal}
            title={t('modals.cancel-request-title')}
            subtitle={t('modals.cancel-request-subtitle')}
            description={t('modals.cancel-request-description')}
            firstBtnText={t('modals.cancel-request-no')}
            firstBtnAction={() => {
              setVisibleCancelModal(false);
            }}
            secondBtnText={t('modals.cancel-request-yes')}
            secondBtnAction={() => {
              cancelServiceRequest();
            }}
          />
          <input type="file" accept="application/pdf" ref={fileInputRef} onChange={onFileChange} style={{ display: 'none' }}/>
          <ModalWindow
            isVisible={isVisibleInvoiceChooseModal}
            setIsVisible={setVisibleInvoiceChooseModal}
            title={t('modals.invoice-type-title')}
            description={t('modals.invoice-type-description')}
            firstBtnText={t('modals.invoice-type-upload')}
            firstBtnAction={async () => {
              setVisibleInvoiceChooseModal(false);
              if (!invoice) {
                await createInvoice('UPLOADED');
              }
              fileInputRef.current.click();
            }}
            secondBtnText={t('modals.invoice-type-generate')}
            secondBtnAction={async () => {
              if (!invoice) {
                await createInvoice('GENERATED');
              }
              setVisibleInvoiceChooseModal(false);
              setVisibleInvoice(true);
            }}
          />
          <ModalWindow
            isVisible={isVisibleConfirmPayment}
            setIsVisible={setVisibleConfirmPayment}
            title={t('modals.confirm-payment-title')}
            subtitle={t('modals.confirm-payment-subtitle')}
            description={t('modals.confirm-payment-description')}
            firstBtnText={t('modals.confirm-payment-no')}
            firstBtnAction={() => {
              setVisibleConfirmPayment(false);
            }}
            secondBtnText={t('modals.confirm-payment-yes')}
            secondBtnAction={() => {
              confirmPayment();
            }}
          />
          <ModalWindow
            isVisible={isSuccessSendVisible}
            setIsVisible={setSuccessSendVisible}
            title={t('modals.payment-title')}
            subtitle={t('modals.payment-subtitle')}
            description={t('modals.payment-description')}
            secondBtnText={t('modals.payment-yes')}
            secondBtnAction={() => {
              setSuccessSendVisible(false);
            }}
          />
          {(data?.status === 'COMPLETED' || data?.status === 'PAID') &&
            (<button className="button button--border" onClick={async () => {
              await refetch();
              if (generatedReportURL?.url) {
                window.open(generatedReportURL?.url, '_blank');
              } else {
                setVisibleErrorModal(true);
              }
            }}>
              <img src={IconPDF} width="20" height="20" alt="Generate a report"/>
              {t('detailServiceRequest.generate-report')}
            </button>)}
          {!(data?.status === 'COMPLETED' || data?.status === 'PAID' || data?.status === 'CANCELLED') &&
            (<button className="button button--text" onClick={() => {
              setVisibleCancelModal(true);
            }}>{t('detailServiceRequest.cancel-request')}</button>)}
        </aside>
      </div>
    </main>);
  }
};

export default DetailServiceRequestWidget;
