// @ts-nocheck
import React, { useState, useEffect, type CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import { PhoneInput } from 'react-international-phone';
import { DateTime } from 'luxon';
import classNames from 'classnames/bind';
import { MenuItem, Select, FormControl, TextareaAutosize, TextField, Autocomplete } from '@mui/material';

import { APIProvider } from '@vis.gl/react-google-maps';

import { type ICustomer } from '@pages/DashboardPage/config/interfaces';

import { TYPE_OF_WORK_LIST } from '@widgets/DetailServiceRequestWidget/config/constants';

import { SERVICE_CATEGORY_LIST } from '../../config/constants';

import { type IServiceRequestClient, type IPhoto, type IVideo } from '../../config/interfaces';

import { AddressMap, UploadPhoto, UploadVideo } from '../index';

import { useGetClientsList } from '../../model/useGetClientsList';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface IRequestFormProps {
  id: string
  date: string
  photos: IPhoto[]
  setPhotos: (value: IPhoto[]) => void
  videos: IVideo[]
  setVideos: (value: IVideo[]) => void
  setLongitude: (arg: number) => void
  setLatitude: (arg: number) => void
  serviceRequestId: string
  selectedUser: ICustomer
  setSelectedUser: (customer: ICustomer) => void
}

interface IPhoneInputStyle extends CSSProperties {
  '--react-international-phone-height': string
  '--react-international-phone-border-radius': string
  '--react-international-phone-font-size': string
  '--react-international-phone-country-selector-background-color-hover': string
}

const RequestForm: React.FC<IRequestFormProps> = ({
  id,
  date,
  photos,
  setPhotos,
  videos,
  setVideos,
  setLongitude,
  setLatitude,
  serviceRequestId,
  selectedUser,
  setSelectedUser,
}) => {
  const { t } = useTranslation();
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [serviceCategory, setServiceCategory] = useState<string>('');

  const { data } = useGetClientsList();
  const clientOptionsList: IServiceRequestClient[] = useMemo(() => (
    data
      ? data.results.map((client) => ({
        name: `${client.first_name} ${client.last_name}`,
        ...client,
      }))
      : []
  ), [data]);
  const clientOptionsForAutocomplete = clientOptionsList.map(client => { return { id: client.id, label: client.name }; });

  const methods = useFormContext();
  const { control, register, watch, formState: { errors }, setValue, trigger } = methods;

  useEffect(() => {
    if (selectedUserId) {
      const currentUser: IServiceRequestClient | undefined = clientOptionsList.find(user => user.id === selectedUserId);
      if (currentUser) {
        setValue('phone', currentUser.phone);
        setSelectedUser(currentUser);
      }
    }
  }, [clientOptionsList, selectedUserId, setValue]);

  const requestDescriptionValue: string = watch().requestDescription;
  const additionalNotesValue: string = watch().additionalNotes;

  const handleUserChange = (event, value): void => {
    setSelectedUserId(value?.id);
  };

  return (
    <div className={cx('form-container')}>
      <div className={cx('form')}>
        <h2>{t('createServiceRequest.new-service-request')}</h2>
        <div className={cx('form__row__group')}>
          <div className={cx('form__row')}>
            <div className={cx('text-bolder')}>{t('createServiceRequest.service-request-ID')}</div>
            <div className={cx('text-bigger', 'text-bold')}>{`#${id}`}</div>
          </div>
          <div className={cx('form__row')}>
            <div className={cx('text-bolder')}>{t('createServiceRequest.created')}</div>
            <div className={cx('text-bigger', 'text-bold')}>{DateTime.fromISO(date).toFormat('dd/LL/yyyy')}</div>
          </div>
        </div>
        <div className={cx('form__row__group')}>
          <div className={cx('form__row')}>
            <div className={cx('form__label')}>{t('createServiceRequest.client')}<span className={cx('required')}>*</span></div>
            <FormControl
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '48px',
                  '& fieldset': {
                    borderColor: 'var(--gray-middle)',
                    borderRadius: '8px',
                    borderWidth: '1px !important',
                    transition: '0.3s',
                  },
                  '&:hover': {
                    '& fieldset': {
                      borderColor: 'var(--gray-middle)',
                    },
                  },
                },
                '& .MuiAutocomplete-root': {
                  '.MuiAutocomplete-input': {
                    height: '100%',
                    padding: '0 6px!important',
                    border: 'none',
                  },
                  '.MuiAutocomplete-clearIndicator': {
                    marginRight: '4px',
                  },
                },
                '& .MuiAutocomplete-listbox': {
                  fontSize: '1.4rem!important',
                },
              }}
            >
              <Controller
                name="userName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  const { onChange, value, ref } = field;
                  return (<Autocomplete
                    {...field}
                    value={
                      value
                        ? clientOptionsForAutocomplete.find((option) => {
                          return value === option.id;
                        }) ?? null
                        : null
                    }
                    disablePortal
                    options={clientOptionsForAutocomplete}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      const resolvedId = newValue ? newValue.id : null;
                      onChange(resolvedId);
                      handleUserChange(event, newValue);
                    }}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        error={!!errors.userName}
                        label=""
                        inputRef={ref}/>
                    }
                  />
                  );
                }}
              />
            </FormControl>
            {errors.userName && <div className={cx('error')}>{t('createServiceRequest.required-field')}</div>}
            {/* <button className={cx('button', 'button--text-small')}> */}
            {/*  <img src={plusIconViolet} width="20" height="20" alt="Add a new client"/> */}
            {/*  Add a new client */}
            {/* </button> */}
          </div>
          <div className={cx('form__row')}>
            <div className={cx('form__label')}>{t('createServiceRequest.phone')}<span className={cx('required')}>*</span></div>
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <PhoneInput
                  className={cx({
                    error: errors.phone,
                  })}
                  disabled={!selectedUserId}
                  defaultCountry="lu"
                  forceDialCode
                  inputStyle={{
                    width: '100%',
                    height: '48px',
                    borderRadius: '8px',
                    borderTopLeftRadius: '0',
                    borderBottomLeftRadius: '0',
                    paddingLeft: '0',
                  }}
                  style={{
                    '--react-international-phone-height': '48px',
                    '--react-international-phone-border-radius': '8px',
                    '--react-international-phone-font-size': '14px',
                    '--react-international-phone-country-selector-background-color-hover': 'white',
                  } as IPhoneInputStyle}
                  {...field}
                />
              )}
            />
            {errors.phone && <div className={cx('error')}>{t('createServiceRequest.required-field')}</div>}
          </div>
        </div>
        <APIProvider apiKey={import.meta.env.APP_GOOGLE_MAPS_KEY} libraries={['marker']}>
          <AddressMap
            selectedUser={selectedUser}
            setLongitude={setLongitude}
            setLatitude={setLatitude}
          />
        </APIProvider>
        <div className={cx('form__row')}>
          <div className={cx('form__label')}>{t('createServiceRequest.service-category')}<span className={cx('required')}>*</span></div>
          <FormControl
            disabled={!selectedUserId}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'var(--gray-middle)',
                  borderRadius: '8px',
                  borderWidth: '1px !important',
                  transition: '0.3s',
                },
                '&:hover': {
                  '& fieldset': {
                    borderColor: 'var(--gray-middle)',
                  },
                },
              },
              '& .Mui-disabled': {
                '& fieldset': {
                  backgroundColor: 'var(--disabled-bg)',
                  border: '1px var(--gray-middle) solid !important',
                  marginTop: '-2px',
                  marginBottom: '-3px',
                },
              },
            }}
          >
            <Select
              className={cx('select-wrapper')}
              IconComponent={() => null}
              value={serviceCategory}
              {...register('serviceCategory', { required: true })}
              onChange={(e) => {
                const value = e.target.value;
                setServiceCategory(value);
                setValue('serviceCategory', value);
                trigger('serviceCategory');
              }}
            >
              {TYPE_OF_WORK_LIST.map((user) => (
                <MenuItem key={user.id} value={user.value} className={cx('select-item')}>{t(user.label)}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* {errors.serviceCategory && <div className={cx('error')}>Required field</div>} */}
        </div>
        <div className={cx('form__row')}>
          <input
            id="emergency"
            type="checkbox"
            value=""
            disabled={!selectedUserId}
            {...register('emergency')}
          />
          <label htmlFor="emergency">
            {t('createServiceRequest.emergency')}
          </label>
        </div>
        <div className={cx('form__row')}>
          <div className={cx('form__label')}>{t('createServiceRequest.request-description')}<span className={cx('required')}>*</span></div>
          <div className={cx('textarea-wrapper')}>
            <TextareaAutosize
              minRows={1}
              placeholder={t('createServiceRequest.request-description-placeholder')}
              disabled={!selectedUserId}
              style={{
                width: '100%',
                boxSizing: 'border-box',
                padding: '0px 12px',
                resize: 'none',
                alignContent: 'center',
              }}
              className={cx({
                error: errors.requestDescription,
              })}
              {...register('requestDescription', {
                maxLength: {
                  value: 500,
                  message: t('createServiceRequest.max-length-description'),
                },
                required: t('createServiceRequest.required-field'),
              })}
            />
            <div className={cx('letter-counter')}>
              {`${requestDescriptionValue ? requestDescriptionValue.length : 0} / 500`}
            </div>
          </div>
          {errors.requestDescription && <div className={cx('error')}>{errors.requestDescription.message}</div>}
        </div>
        <UploadVideo
          selectedUser={selectedUserId}
          videos={videos}
          setVideos={setVideos}
          serviceRequestId={serviceRequestId}
        />
        <UploadPhoto
          selectedUser={selectedUserId}
          photos={photos}
          setPhotos={setPhotos}
          serviceRequestId={serviceRequestId}
        />
        <div className={cx('form__row')}>
          <div className={cx('form__label')}>{t('createServiceRequest.additional-notes')}</div>
          <div className={cx('textarea-wrapper')}>
            <TextareaAutosize
              minRows={1}
              placeholder={t('createServiceRequest.additional-notes-placeholder')}
              disabled={!selectedUserId}
              style={{
                width: '100%',
                boxSizing: 'border-box',
                padding: '0px 12px',
                resize: 'none',
                alignContent: 'center',
              }}
              {...register('additionalNotes', { maxLength: 500 })}
            />
            <div className={cx('letter-counter')}>
              {`${additionalNotesValue ? additionalNotesValue.length : 0} / 500`}
            </div>
          </div>
          {errors.additionalNotes && <div className={cx('error')}>{t('createServiceRequest.max-length-description')}</div>}
        </div>
      </div>
    </div>
  );
};

export default RequestForm;
