// @ts-nocheck
/* TODO: Проверка отключена из-за google-maps-react.
    Ошибка в импортах и <Map> не может иметь {children}, функционал работает */
import React, { useState, useEffect, useCallback, type FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import {
  AdvancedMarker,
  Map,
  useMap,
  useMapsLibrary,
} from '@vis.gl/react-google-maps';
import { useGeocodeService } from '@shared/lib/hooks/useGeocodeService';
import iconMarker from '@shared/assets/Icons/icon_marker.svg';

import classNames from 'classnames/bind';

import { type IServiceRequestClient } from '@widgets/CreateServiceRequestWidget/config/interfaces';

import '../../../DetailServiceRequestWidget/ui/ClientsMap/styles.scss';
import { LuxembourgLocation } from '@shared/config/map/constants';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface AddressPickerProps {
  setLongitude: (arg: number) => void
  setLatitude: (arg: number) => void
  selectedUser: IServiceRequestClient | null
}

interface AddressComponent {
  types: string[]
}

const AddressMap: React.FC<AddressPickerProps> = ({ setLongitude, setLatitude, selectedUser }) => {
  const { t } = useTranslation();
  const [address, setAddress] = useState<string>('');
  const [addressInput, setAddressInput] = useState<string>('');
  const [markerPosition, setMarkerPosition] = useState(LuxembourgLocation);
  const [map, setMap] = useState(null);
  const [geocoder, setGeocoder] = useState(null);
  const [city, setCity] = useState<string>('');
  const [postcode, setPostcode] = useState<string>('');
  const [country, setCountry] = useState<string>('');

  const places = useMapsLibrary('places');
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>();
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);
  const [predictionResults, setPredictionResults] = useState<google.maps.places.AutocompletePrediction[]>([]);

  const [isVisibleMap, setVisibleMap] = useState<boolean>(false);
  const geocoderService = useGeocodeService();
  const mapInstance = useMap();
  useEffect(() => {
    if (!geocoderService) return;
    setGeocoder(geocoderService);
  }, [geocoderService]);

  useEffect(() => {
    if (!places || !map) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => { setAutocompleteService(null); };
  }, [map, places]);

  useEffect(() => {
    if (!mapInstance) return;
    setMap(mapInstance);
  }, [mapInstance]);

  const methods = useFormContext();
  const { register, formState: { errors }, setValue, trigger } = methods;

  useEffect(() => {
    const street = selectedUser?.address?.street ?? '';
    const city = selectedUser?.address?.city ?? '';
    const country = selectedUser?.address?.country ?? '';
    setAddressInput(selectedUser && (!!street || !!city || !!country)
      ? (street + ', ' + city + ', ' + country)
      : '');
    setAddress(selectedUser ? (selectedUser?.address.street) : '');
    setCity(selectedUser ? selectedUser?.address.city : '');
    setPostcode(selectedUser ? selectedUser?.address.postcode : '');
    setCountry(selectedUser ? selectedUser?.address.country : '');
    setMarkerPosition(selectedUser?.address?.location
      ? { lat: selectedUser?.address?.location?.latitude, lng: selectedUser?.address?.location?.longitude }
      : LuxembourgLocation);
  }, [selectedUser]);

  useEffect(() => {
    setValue('fullAddress', addressInput);
    trigger('fullAddress');
  }, [addressInput, setValue]);

  useEffect(() => {
    setValue('city', city);
    trigger('city');
  }, [city, setValue]);

  useEffect(() => {
    setValue('postcode', postcode);
    trigger('postcode');
  }, [postcode, setValue]);

  useEffect(() => {
    setValue('address', address);
    trigger('address');
  }, [address, setValue]);

  useEffect(() => {
    setValue('country', country);
    trigger('country');
  }, [country, setValue]);

  useEffect(() => {
    setVisibleMap(false);
  }, [selectedUser]);

  const fetchPredictions = useCallback(
    async (inputValue: string) => {
      if (!autocompleteService || !inputValue) {
        setPredictionResults([]);
        return;
      }

      const request = { input: inputValue, sessionToken };
      const response = await autocompleteService.getPlacePredictions(request);

      setPredictionResults(response.predictions);
    },
    [autocompleteService, sessionToken],
  );

  const onInputChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const value = (event.target as HTMLInputElement)?.value;
      setAddressInput(value);
      fetchPredictions(value);
    },
    [fetchPredictions],
  );

  const fetchAddress = (lat: number, lng: number): void => {
    geocoder.geocode({ location: { lat, lng }, language: 'en' }, (results, status: google.maps.GeocoderStatus) => {
      if (status === 'OK' && results && results.length > 0) {
        setAddressInput(results[0].formatted_address as string);
        const addressComponents = results[0].address_components;
        const routeComponent = addressComponents.find((component: AddressComponent) =>
          component.types.includes('route'),
        );
        const streetNumberComponent = addressComponents.find((component: AddressComponent) =>
          component.types.includes('street_number'),
        );
        if (streetNumberComponent && routeComponent &&
            typeof streetNumberComponent.long_name === 'string' &&
            typeof routeComponent.long_name === 'string') {
          setAddress(streetNumberComponent.long_name + ' ' + routeComponent.long_name);
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('Geocode was not successful for the following reason:', status);
      }
    });
  };

  const fetchCity = (lat: number, lng: number): void => {
    geocoder.geocode({ location: { lat, lng }, language: 'en' }, (results, status: google.maps.GeocoderStatus) => {
      if (status === 'OK' && results && results.length > 0) {
        const addressComponents = results[0].address_components;
        const cityComponent = addressComponents.find((component: AddressComponent) =>
          component.types.includes('locality'),
        );
        if (cityComponent && typeof cityComponent.long_name === 'string') {
          setCity(cityComponent.long_name as string);
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('Geocode was not successful for the following reason:', status);
      }
    });
  };

  const fetchPostcode = (lat: number, lng: number): void => {
    geocoder.geocode({ location: { lat, lng } }, (results: GeocoderResult[], status: google.maps.GeocoderStatus) => {
      if (status === 'OK' && results && results.length > 0) {
        const addressComponents = results[0].address_components;
        const postalCodeComponent = addressComponents.find((component: AddressComponent) =>
          component.types.includes('postal_code'),
        );
        if (postalCodeComponent && typeof postalCodeComponent.long_name === 'string') {
          setPostcode(postalCodeComponent.long_name as string);
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('Geocode was not successful for the following reason:', status);
      }
    });
  };

  const fetchCountry = (lat: number, lng: number): void => {
    geocoder.geocode({ location: { lat, lng }, language: 'en' }, (results: GeocoderResult[], status: google.maps.GeocoderStatus) => {
      if (status === 'OK' && results && results.length > 0) {
        const addressComponents = results[0].address_components;
        const countryComponent = addressComponents.find((component: AddressComponent) =>
          component.types.includes('country'),
        );
        if (countryComponent && typeof countryComponent.long_name === 'string') {
          setCountry(countryComponent.long_name as string);
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('Geocode was not successful for the following reason:', status);
      }
    });
  };

  const onMapClick = (clickEvent): void => {
    const lat: number = clickEvent.detail.latLng.lat;
    const lng: number = clickEvent.detail.latLng.lng;
    setMarkerPosition({ lat, lng });
    map?.panTo({ lat, lng });
    fetchAddress(lat, lng);
    fetchCity(lat, lng);
    fetchPostcode(lat, lng);
    fetchCountry(lat, lng);
    setLongitude(lng);
    setLatitude(lat);
  };

  const onMarkerClick = (): void => {
    geocoder.geocode({ location: markerPosition, language: 'en' }, (results: GeocoderResult[], status: google.maps.GeocoderStatus) => {
      if (status === 'OK' && results && results.length > 0) {
        setAddress(results[0].formatted_address as string);
      } else {
        // eslint-disable-next-line no-console
        console.error('Geocode was not successful for the following reason:', status);
      }
    });
  };

  const handleSearch = useCallback((): void => {
    if (addressInput.trim() !== '') {
      geocoder.geocode({ address: addressInput }, (results: GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status === 'OK' && results && results.length > 0) {
          const { lat, lng } = results[0].geometry.location;
          const latitude: number = typeof lat === 'function' ? lat() : lat;
          const longitude: number = typeof lng === 'function' ? lng() : lng;
          setMarkerPosition({ lat: latitude, lng: longitude });
          map?.panTo({ lat: latitude, lng: longitude });
          fetchAddress(latitude, longitude);
          fetchCity(latitude, longitude);
          fetchPostcode(latitude, longitude);
          fetchCountry(latitude, longitude);
          setLongitude(longitude);
          setLatitude(latitude);
        } else {
          // eslint-disable-next-line no-console
          console.error('Geocode was not successful for the following reason:', status);
        }
      });
    }
  }, [addressInput, fetchAddress, fetchCity, fetchCountry, fetchPostcode, geocoder, map, setLatitude, setLongitude]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };

  useEffect(() => {
    if (geocoder) {
      handleSearch();
    }
  }, [geocoder]);

  const handleSuggestionClick = useCallback(
    (placeId: string, description: string) => {
      if (!places) return;
      const detailRequestOptions = {
        placeId,
        fields: ['geometry', 'name', 'formatted_address'],
        sessionToken,
      };

      setAddressInput(description ?? '');

      const detailsRequestCallback = () => {
        setPredictionResults([]);
        setAddressInput(description ?? '');
        setSessionToken(new places.AutocompleteSessionToken());
      };

      placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
      handleSearch();
    },
    [handleSearch, places, placesService, sessionToken],
  );

  return (
    <div className={cx('form__row')}>
      <div className={cx('form__label')}>{t('createServiceRequest.address')}<span className={cx('required')}>*</span></div>
      <div className={cx('form__text')}>{t('createServiceRequest.address-info')}</div>
      <input
        id="addressInput"
        name="addressInput"
        type="text"
        placeholder="Address"
        disabled={!selectedUser}
        className={cx('input--map', {
          error: selectedUser && !addressInput,
        })}
        {...register('fullAddress', {
          required: true,
        })}
        value={addressInput}
        onChange={(e) => { onInputChange(e); }}
        onKeyDown={handleKeyPress}
        onClick={() => {
          setVisibleMap(true);
          if (!selectedUser?.address?.location) {
            handleSearch();
            trigger();
          }
        }}
      />
      {selectedUser && !addressInput && (<div className={cx('error')}>Required field</div>)}
      {predictionResults.length > 0 && isVisibleMap && (
        <ul className={cx('custom-list')}>
          {predictionResults.map(({ place_id, description }) => {
            return (
              <li
                key={place_id}
                className={cx('custom-list-item')}
                onClick={() => { handleSuggestionClick(place_id, description); }}>
                {description}
              </li>
            );
          })}
        </ul>
      )}
      {isVisibleMap && (
        <>
          <Map
            mapId={'bf51a34'}
            style={{ width: '100%', height: '300px' }}
            defaultCenter={markerPosition}
            defaultZoom={15}
            gestureHandling={'greedy'}
            disableDefaultUI
            onClick={(e) => { onMapClick(e); }}
          >
            <AdvancedMarker
              position={markerPosition}
              onClick={onMarkerClick}
            >
              <div className="custom-marker__container">
                <img className="custom-marker__icon" src={iconMarker} alt="marker"/>
                <div className="custom-marker__person">
                  {selectedUser?.first_name[0]}{selectedUser?.last_name[0]}
                </div>
                <span className="custom-marker__info-client">
                  {selectedUser?.first_name}
                </span>
              </div>
            </AdvancedMarker>
          </Map>
          <div className={cx('form__row__group', 'gap-12')}>
            <div className={cx('form__row')}>
              <div className={cx('form__label')}>{t('createServiceRequest.address-line')}<span className={cx('required')}>*</span></div>
              <input
                id="address"
                name="address"
                type="text"
                placeholder={t('createServiceRequest.address-line')}
                disabled={!selectedUser}
                className={cx({
                  error: errors.address,
                })}
                value={address}
                {...register('address', {
                  required: true,
                })}
              />
              {errors.address && <div className={cx('error')}>Required field</div>}
            </div>
            <div className={cx('form__row')}>
              <div className={cx('form__label')}>{t('createServiceRequest.postcode')}<span className={cx('required')}>*</span></div>
              <input
                type="text"
                placeholder={t('createServiceRequest.postcode')}
                disabled={!selectedUser}
                className={cx({
                  error: errors.postcode,
                })}
                {...register('postcode', {
                  required: true,
                })}
              />
              {errors.postcode && <div className={cx('error')}>{t('createServiceRequest.required-field')}</div>}
            </div>
          </div>
          <div className={cx('form__row__group', 'gap-12')}>
            <div className={cx('form__row')}>
              <div className={cx('form__label')}>{t('createServiceRequest.city')}<span className={cx('required')}>*</span></div>
              <input
                type="text"
                disabled
                className={cx({
                  error: errors.city,
                })}
                {...register('city')}
              />
            </div>
            <div className={cx('form__row')}>
              <div className={cx('form__label')}>{t('createServiceRequest.country')}<span className={cx('required')}>*</span></div>
              <input
                type="text"
                disabled
                className={cx({
                  error: errors.country,
                })}
                {...register('country')}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AddressMap;
