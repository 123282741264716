import React from 'react';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from '@shared/HOC';

const AuthLayout: React.FC = () => {
  return (
    <>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </>
  );
};

export default AuthLayout;
